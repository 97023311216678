import React from "react";
import "./improveFinanceLiteracy.css";
import finance_habits from "../../Assets/finance_strategies.png";

function ImproveFinLit() {
  const tips = [
    {
      heading: "Starting Early",
      content: `Encouraging financial education from a young age lays a strong foundation for lifelong financial literacy. Schools, 
      families, and communities play a vital role in promoting financial awareness and teaching children essential money management skills.`,
    },
    {
      heading: "Seeking Professional Advice",
      content: `Seeking advice from qualified financial professionals can help individuals gain clarity and confidence in their financial decisions. 
      Financial advisors can provide personalized guidance, tailored to individual goals and circumstances.`,
    },
  ];

  return (
    <div>
      <div>
        <div className="adoptHabits">
          <h1>Approaches to Enhancing Financial Literacy.</h1>
        </div>
        <div className="habitCardsWrapper  d-flex flex-wrap">
          {tips.map((eachCard) => (
            <div className="habitCard ">
              <h2 className="habitCardHeading">{eachCard.heading}</h2>
              <div className="habitCardContent">{eachCard.content}</div>
            </div>
          ))}
        </div>
      </div>
      <div className="habitContentImage d-flex  align-items-center ">
        <div className="habitContent ">
          <h2 className="habitCardHeading">Continuous Learning</h2>
          <div className="habitCardContent ">
            Financial literacy is an ongoing process that requires continuous
            learning and self-improvement. Individuals should stay informed
            about changes in the financial landscape, update their knowledge and
            skills regularly, and adapt their financial strategies accordingly.
          </div>
        </div>
        <div className="habitImage d-flex justify-content-end">
          <img src={finance_habits} alt="" />
        </div>
      </div>
    </div>
  );
}

export default ImproveFinLit;
