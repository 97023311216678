import React, { useState, useEffect, useContext } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from "axios";
import { FaPencilAlt } from "react-icons/fa";
import { GiPin } from "react-icons/gi";
import { MdDelete } from "react-icons/md";
import { FaArrowLeftLong } from "react-icons/fa6";
import { RxDrawingPin } from "react-icons/rx";
import { AuthContext } from "../../App";
import config from "../../Boot/config.json";
import "./financeBlogPage.css";
import FinanceBlogsEditor from "../FinanceBlogsEditor/FinanceBlogEditor";
import farmersPlaceholder from "../../Assets/rupees.png";

function FinanceBlogPage() {
  const { tokenData, univPage, setUnivPage } = useContext(AuthContext);
  const [editClicked, setEditClicked] = useState(false);
  const [deleteStatus, setDeleteStatus] = useState(false);
  const [favCount, setfavCount] = useState(0);
  const [fav, setFav] = useState(false);
  const [showImage, setShowImage] = useState(true);
  const [userIdentification, setUserIdentification] = useState("");

  const navigate = useNavigate();

  const permissions = {
    showEditButton: (tokenData, adminApproval, id) => {
      return (
        tokenData.roleId === "Admin" ||
        ((tokenData.roleId === "Guest" || tokenData.roleId === "User") &&
          (adminApproval === "approved" ||
            adminApproval === "rejected" ||
            adminApproval === "working") &&
          tokenData.userId === userIdentification)
      );
    },
    showDeleteButton: (tokenData, adminApproval) => {
      return (
        tokenData.roleId === "Admin" ||
        ((tokenData.roleId === "Guest" || tokenData.roleId === "User") &&
          (adminApproval === "rejected" || adminApproval === "working"))
      );
    },
  };

  const handleClick = () => {
    setEditClicked(true);
  };

  const axiosInstance = axios.create({
    baseURL: `${config.baseUrl}/Blogs`,
    headers: {
      Authorization: tokenData.token,
    },
  });

  const { id } = useParams();
  // console.log(id);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const page = queryParams.get("page");
  const [data, setData] = useState({});
  const url = `${config.baseUrl}/${location.pathname}`;
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${config.baseUrl}/Blogs/getBlog/${id}`
        );
        console.log("singleBlog data", response.data.data);
        setfavCount(response.data.favCount);
        setUserIdentification(response.data.userId);

        setData(response.data.data);

        setFav(data.fav);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the fetchData function when the component mounts
    fetchData();
  }, [id, deleteStatus, fav]);

  const deleteBlog = async (e, id) => {
    try {
      await axiosInstance.delete(`/deleteBlog/${id}`);
      setDeleteStatus(!deleteStatus);
      navigate(`/blogs`);
    } catch (err) {
      console.log(err);
    }
  };

  const addTWL = async (e, id, value) => {
    e.preventDefault();
    e.stopPropagation();

    try {
      const formData = {
        fav: value,
      };
      if (favCount < 9) {
        await axiosInstance.patch(`/favUnFav/${id}`, formData);
        setFav(!fav);
      } else if (favCount >= 9 && value === false) {
        await axiosInstance.patch(`/favUnFav/${id}`, formData);
        setFav(!fav);
      } else {
        alert("Reached max number of pins");
      }
    } catch (err) {
      console.log(err);
    }
  };

  if (!data) {
    return <p> Blog not found</p>;
  }
  if (editClicked) {
    return (
      <FinanceBlogsEditor
        editBlog={data.blogName}
        editImage={data.Image}
        editCategory={data.category}
        editDescription={data.Data}
        editPreview={data.preview}
        editSeoTitle={data.seoTitle}
        editMetaDescription={data.metaDescription}
        editSeoSlug={data.seoSlug}
        editStatus={true}
      />
    );
  }

  const handleBackClick = () => {
    const prevLocation = JSON.parse(sessionStorage.getItem("location"));
    if (prevLocation === "/protected/myBlogs") {
      navigate(prevLocation);
    } else {
      navigate(-1); // This will go back to the last page in history
    }
  };

  return (
    <div className="mainBlogPageSection">
      <Helmet>
        {data.seoTitle && data.seoTitle.length > 0 ? (
          <title>{`${data.seoTitle}`}</title>
        ) : (
          <title>{`${data.blogName} | Kakatiya Capital`}</title>
        )}
        {data.metaDescription && data.metaDescription.length > 0 ? (
          <meta name="description" content={data.metaDescription} />
        ) : (
          <meta
            name="description"
            content="Explore insightful articles and resources on financial awareness, NBFC partnerships, and innovative financial solutions. Kakatiya Capital's blog offers valuable information to help individuals and businesses navigate the financial landscape effectively."
          />
        )}
      </Helmet>
      <div
        className="backToBlog py-1 px-2 text-decoration-none position-fixed text-center"
        onClick={() => {
          handleBackClick();
        }}
      >
        <FaArrowLeftLong className="me-1" />
      </div>
      {
        <div className="float-end d-none d-md-block position-absolute end-0">
          {permissions.showDeleteButton(tokenData, data.adminApproval) && (
            <button
              className="position-relative text-center del editbPost border-0"
              onClick={(e) => deleteBlog(e, id)}
            >
              <MdDelete className="fs-3" />
            </button>
          )}
          <>
            {tokenData.roleId === "Admin" && (
              <>
                {data.fav ? (
                  <button
                    className="text-decoration-none position-relative text-center editbPost pinned border-1"
                    onClick={(e) => addTWL(e, id, !data.fav)}
                  >
                    <GiPin className="fs-3 " />
                  </button>
                ) : (
                  <button
                    className="position-relative text-center editbPost rmPin border-1"
                    onClick={(e) => addTWL(e, id, true)}
                  >
                    {/* <RxDrawingPin className="fs-3 text-success" /> */}
                    <GiPin className="fs-3 text-success" />
                  </button>
                )}
              </>
            )}
          </>
          {permissions.showEditButton(
            tokenData,
            data.adminApproval,
            data.userId
          ) && (
            <button
              onClick={handleClick}
              className="editbPost edit2 text-decoration-none position-relative text-center float-end border-0"
            >
              <FaPencilAlt />
            </button>
          )}
        </div>
      }

      <div className="d-lg-flex justify-content-around justify-content-xl-center">
        <div className="blogDetailPage d-block p-4">
          <h1 className="Blog_heading">{data.blogName}</h1>
          <img
            src={
              data.Image === ""
                ? farmersPlaceholder
                : `${config.deployUrl}${data.Image}`
            }
            alt={data.blogName}
            className="Blog_Image"
            loading="lazy"
          />
          <p className="Blog_DetailedContent">
            <span className="invisible">.........</span>
            <div dangerouslySetInnerHTML={{ __html: data.Data }} />
            <div className="publishedUser">
              <div>
                PUBLISHED BY - <strong>{data.author}</strong>
              </div>
              <div>
                <strong>{data.publishedOn}</strong>
              </div>
            </div>
          </p>
        </div>
      </div>
    </div>
  );
}

export default FinanceBlogPage;
