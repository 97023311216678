import React, { useState, useContext } from "react";
import IndividualNbfc from "./IndividualNbfc";
import Nbfcs from "./Nbfcs";
import "./nbfcView.css";
import { Helmet } from "react-helmet";
import { AuthContext } from "../../App";

function NbfcView() {
  const [activeComponent, setActiveComponent] = useState("nbfcs");

  const { tokenData } = useContext(AuthContext);

  return (
    <div>
      <Helmet>
        <title> Financial Solutions | Kakatiya Capital </title>
        <meta
          name="description"
          content="Discover financial solutions with Kakatiya Capital. We link NBFC banks for strategic partnerships, enhancing financial awareness and providing innovative services."
        />
      </Helmet>

      {tokenData.token && tokenData.roleId === "User" && (
        <>
          <div className="status-bar shadow-sm">
            <button
              onClick={() => setActiveComponent("nbfcs")}
              className={
                activeComponent === "nbfcs" ? "active border-0" : "border-0"
              }
            >
              All NBFCs
            </button>
            <button
              onClick={() => setActiveComponent("individualNbfc")}
              className={
                activeComponent === "individualNbfc"
                  ? "active border-0"
                  : "border-0"
              }
            >
              My NBFC
            </button>
          </div>
        </>
      )}

      {activeComponent === "nbfcs" && <Nbfcs />}
      {activeComponent === "individualNbfc" && (
        <IndividualNbfc nbfcIds={tokenData.nbfcId} />
      )}
    </div>
  );
}

export default NbfcView;
