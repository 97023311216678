import React, { createContext, useState, useEffect } from "react";
import AlertDismissible from "./components/Utils/Alert";
import "./App.css";
import Navigation from "./components/Navigation/Navigation";
import PathConfig from "./components/Router/PathConfig";
export const AuthContext = createContext();

function App() {
  const [tokenData, setTokenData] = useState({
    token: "",
    roleId: "",
    userId: "",
    nbfcId: "",
    alertMsg: "",
    alertValue: false,
    alertStatus: true,
    profileStatus: false,
    blogSearch: "",
  });
  const [pageNum, setPageNum] = useState(1);
  const [univPage, setUnivPage] = useState(1);
  const [paginNum, setPaginNum] = useState(1);

  const handleLogout = () => {
    sessionStorage.removeItem("JaikisanJWT");
    setTokenData((prevState) => ({
      ...prevState,
      token: "",
      roleId: "",
      userId: "",
      nbfcId: "",
      alertMsg: "",
      alertValue: false,
      alertStatus: true,
      blogSearch: "",
    }));
  };

  useEffect(() => {
    const storedToken = sessionStorage.getItem("JaikisanJWT");
    if (storedToken) {
      const authData = JSON.parse(storedToken);
      setTokenData(authData);
    }
  }, []);

  const logout = () => {
    return tokenData ? (
      <div onClick={handleLogout}>Logout</div>
    ) : (
      <div style={{ display: "none" }}></div>
    );
  };

  return (
    <AuthContext.Provider
      value={{
        tokenData,
        handleLogout,
        logout,
        setTokenData,
        univPage,
        setUnivPage,
        pageNum,
        setPageNum,
        paginNum,
        setPaginNum,
      }}
    >
      <div className="App">
        <PathConfig />
        <Navigation />
        <div
          className={`position-fixed   ${
            tokenData.alertValue ? "animateAlert " : "hideAlert"
          }`}
        >
          {tokenData.alertValue && (
            <AlertDismissible
              message={tokenData.alertMsg}
              value={tokenData.alertStatus}
            />
          )}
        </div>
      </div>
    </AuthContext.Provider>
  );
}

export default App;
