import React, { useContext } from "react";
import { Routes, Route, Outlet, Navigate } from "react-router-dom";
import FinanceBlogs from "../FinanceBlogs/FinanceBlogs";
import KakatiyaHome from "../KakatiyaHome/KakatiyaHome";
import Login from "../LoginPage/Login";
import { AuthContext } from "../../App";
import FinanceBlogEditor from "../FinanceBlogsEditor/FinanceBlogEditor";
import FinanceBlogPage from "../FinanceBlogs/FinanceBlogPage";
import MyBlogs from "../MyBlogs/MyBlogs";
import CareerPage from "../Jobs/CareerPage";
import CareerDescription from "../Jobs/CareerDescription";
import ApplicationForm from "../Jobs/ApplicationForm";
import NbfcView from "../Nbfc/NbfcView";
import IndividualNbfc from "../Nbfc/IndividualNbfc";
import JobEditor from "../JobEditor/JobEditor";
import Users from "../Users/Users";
import AddUser from "../Users/AddUser";
import UserProfile from "../Users/UserProfile";
import Error404 from "../ErrorPage/Error404";

function PrivateRoutes() {
  const { tokenData } = useContext(AuthContext);
  return tokenData.token ? <Outlet /> : <Navigate to="/blogs" />;
}
function PathConfig() {
  return (
    <div className="pathConfig">
      <Routes>
        <Route path="/blogs" element={<FinanceBlogs />} />
        <Route path="/home" element={<KakatiyaHome />} />
        <Route path="/" element={<KakatiyaHome />} />
        <Route path="/admin" element={<Login />} />
        <Route path="/careers" element={<CareerPage />} />
        <Route path="/blog/:title/:id" element={<FinanceBlogPage />} />
        <Route path="/careers/:title/:jobId" element={<CareerDescription />} />
        <Route path="/applylink/:title/:jobId" element={<ApplicationForm />} />
        <Route path="/nbfcs" element={<NbfcView />} />
        <Route path="/nbfc/:nbfcName/:nbfcId" element={<IndividualNbfc />} />

        <Route path="/protected" element={<PrivateRoutes />}>
          <Route index element={<Navigate to="/protected/addPost" />} />
          <Route path="/protected/addPost" element={<FinanceBlogEditor />} />
          <Route path="/protected/myBlogs" element={<MyBlogs />} />
          <Route path="/protected/addJob" element={<JobEditor />} />
          <Route path="/protected/Users" element={<Users />} />
          <Route path="/protected/addUsers" element={<AddUser />} />
          <Route
            path="/protected/UserProfile/:userId"
            element={<UserProfile />}
          />
        </Route>
        <Route path="*" element={<Error404 />} />
      </Routes>
    </div>
  );
}
export default PathConfig;
