import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { AuthContext } from "../../App";
import config from "../../Boot/config.json";
import "./nbfcs.css";
import { Link } from "react-router-dom";
import nbfcFallBackImg from "../../Assets/nbfcFallBackImg.png";
import NoData from "../../Assets/NoData.jpg";

function Nbfcs() {
  const { tokenData } = useContext(AuthContext);
  const [nbfcData, setNbfcData] = useState({
    nbfcs: [],
    loading: true,
  });

  useEffect(() => {
    if (tokenData.token) {
      fetchNbfcs();
    }
  }, [tokenData]);

  function urlEncoded(title) {
    return title
      .toLowerCase() // Optional: convert to lowercase
      .replace(/[^a-z0-9]+/g, "-") // Replace non-alphanumeric characters with hyphens
      .replace(/^-+|-+$/g, ""); // Remove leading and trailing hyphens
  }

  const axiosInstance = axios.create({
    baseURL: `${config.baseUrl}/Nbfcs`,
    headers: {
      Authorization: tokenData.token,
    },
  });

  const fetchNbfcs = () => {
    setNbfcData((prevnbfcData) => ({ ...prevnbfcData, loading: true }));

    axiosInstance
      .get("/allNbfcs")
      .then((response) => {
        setNbfcData((prevnbfcData) => ({
          ...prevnbfcData,
          nbfcs: response.data.data,
          loading: false,
        }));
      })
      .catch((error) => {
        console.error("There was an error fetching the agencies!", error);
        setNbfcData((prevnbfcData) => ({
          ...prevnbfcData,
          loading: false,
        }));
      });
  };

  if (nbfcData.loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="nbfcs-container">
      {nbfcData.nbfcs && nbfcData.nbfcs.length === 0 && (
        <>
          <div className="d-flex flex-column justify-content-center align-items-center noNbfcDataWrapper position-relative overflow-hidden ">
            <img src={NoData} alt="no agecies found" className="noNbfcsFound" />
            <h2>No Nbfcs Found</h2>
          </div>
        </>
      )}
      <div className="nbfcs-grid mt-2 mt-sm-4">
        {nbfcData.nbfcs.map((nbfc) => (
          <div key={nbfc._id} className="nbfc-card position-relative">
            <div className="nbfc-card-image position-relative">
              <img
                src={
                  nbfc.nbfcImage !== ""
                    ? `${config.deployUrl}${nbfc.nbfcImage}`
                    : nbfcFallBackImg
                }
                alt={nbfc.nbfcName || "No Nbfc Name"}
              />
              <div
                className={`position-absolute top-0 end-0 p-2 text-white ${
                  nbfc.verified ? "bg-success" : "bg-danger"
                }`}
              >
                {nbfc.verified ? "Active" : "Inactive"}
              </div>
            </div>
            <div className="nbfc-card-content position-relative">
              <div className="d-flex justify-content-between">
                <h2 title={nbfc.nbfcName}>
                  {nbfc.nbfcName && nbfc.nbfcName.length > 8
                    ? nbfc.nbfcName.slice(0, 8) + "..."
                    : nbfc.nbfcName || "No Nbfc Name"}
                </h2>
                <div className="ratingColor px-3 ">☆ {nbfc.ratingNbfc}</div>
              </div>
              <p className="nbfcbout">
                {nbfc.about && nbfc.about.length > 100
                  ? nbfc.about.slice(0, 100) + "..."
                  : nbfc.about ||
                    "Share your company's mission, services, and uniqueness to stand out among competitors."}
              </p>
              <p>
                <strong>Nbfc Code:</strong> {nbfc.nbfcCode}
              </p>
              <p title={nbfc.ownerName}>
                <strong> Name: </strong>
                {nbfc.email && nbfc.ownerName.length > 20
                  ? nbfc.ownerName.slice(0, 20) + "..."
                  : nbfc.ownerName || "N/A"}
              </p>
            </div>

            <div className=" position-absolute bottom-0 w-100 mb-3">
              <Link
                to={`/nbfc/${urlEncoded(nbfc.nbfcName)}/${nbfc.kcAgencyId.slice(
                  4,
                  10
                )}`}
                className="d-flex justify-content-center"
              >
                <button className="w-50">View More</button>
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Nbfcs;
