import React from "react";
import "./impOfFinLitOnInd.css";
import betterDecisionMaking from "../../Assets/decision-making.png";
import debtMangement from "../../Assets/human.png";
import wealthBuilding from "../../Assets/building.png";
import impactOfIndFinanLitImage from "../../Assets/6425322-removebg-preview.png";

function ImpactOfFinLitIndividual() {
  const financeIndCards = [
    {
      icon: betterDecisionMaking,
      heading: "Better Decision Making",
      content: `Individuals with high financial literacy levels are more likely to make informed financial decisions.
         They can evaluate various financial products and services effectively, choosing the ones that best suit their needs and goals.`,
    },
    {
      icon: debtMangement,
      heading: "Debt Management",
      content: `Financially literate individuals are better equipped to manage debt responsibly. 
      They understand the consequences of borrowing, avoid high-interest debt, and develop strategies to repay existing debts efficiently.`,
    },
    {
      icon: wealthBuilding,
      heading: "Wealth Building",
      content: `Financial literacy lays the foundation for wealth building. 
      It empowers individuals to set financial goals, develop saving and investment plans, 
      and take proactive steps towards building long-term wealth and financial security.
      `,
    },
  ];
  return (
    <div>
      <div className="impactOfFinanceLitOnIndOverviewWrapper">
        <h1>Impact of Financial Literacy on Individuals</h1>
      </div>
      <div className="impactOfFinanceIndividualCardsWrapper d-flex justify-content-center align-items-center">
        <div className="impactsOfFinanceIndImageWrapper d-flex justify-content-center ">
          <img
            src={impactOfIndFinanLitImage}
            className="impactOfIndFinImage"
            alt=""
          />
        </div>
        <div className="impactsOfFinanceIndCards d-flex">
          {financeIndCards.map((eachCard) => (
            <div className="FinanceLitOnIndividualCard d-flex justify-content-center align-items-center">
              <div className="indFinanceLitImageWrapper  rounded-circle   ">
                <img
                  src={eachCard.icon}
                  className="individualFinanceLitImage d-none d-sm-block"
                  alt=""
                />
              </div>
              <div className="indFinanceLitContentWrapper">
                <h2>{eachCard.heading}</h2>
                <div>{eachCard.content}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ImpactOfFinLitIndividual;
