import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useTable, usePagination, useGlobalFilter } from "react-table";
import { FaSearch } from "react-icons/fa";
import ReactPaginate from "react-paginate";
import "./users.css";
import config from "../../Boot/config.json";
import { AuthContext } from "../../App";
import { Helmet } from "react-helmet";
import { RxCross2 } from "react-icons/rx";

function Users() {
  const { tokenData } = useContext(AuthContext);
  const navigate = useNavigate();

  const [pageCount, setPageCount] = useState(0);
  const userStatusBar =
    JSON.parse(sessionStorage.getItem("userStatusBar")) || {};
  const [userData, setUserData] = useState({
    selectedStatus: userStatusBar.selectedStatus || "allUsers",
    users: [],
    page: userStatusBar.page || 0,
    loading: true,
    search: "", // Added search term
  });
  const [pageIndex, setPageIndex] = useState(0);
  const [globalFilter, setGlobalFilter] = useState("");
  const [searchStat, setSearchState] = useState(false);
  const [searchStatPage, setSearchStatPage] = useState(false);
  const [paginStat, setPaginStat] = useState(false);

  useEffect(() => {
    fetchUsers(
      userData.selectedStatus,
      userData.search,
      userData.page,
      paginStat
    );
    sessionStorage.setItem("userStatusBar", JSON.stringify(userData));
    const userTblDataExists = JSON.parse(
      sessionStorage.getItem("userStatusBar")
    );
  }, [userData.selectedStatus, searchStat, paginStat]);

  const axiosInstance = axios.create({
    baseURL: `${config.baseUrl}/Users/GuestUsers`,
    headers: {
      Authorization: tokenData.token,
    },
  });

  const fetchUsers = (status, search, page, pageStat) => {
    setUserData((prevUserData) => ({ ...prevUserData, loading: true }));
    if (searchStatPage && pageStat) {
      console.log("stat");
    } else if (searchStat) {
      page = 0;
    }
    console.log("console status", status);
    console.log("console status", page);
    const formData = {
      page: page,
      search: search, // Include search term in the request
    };
    axiosInstance
      .post(`/${status}`, formData)
      .then((response) => {
        setUserData((prevUserData) => ({
          ...prevUserData,
          users: response.data.data,
          loading: false,
        }));
        console.log("backend page ", response.data.currentPage);
        console.log("frontend page", page);
        if (search) {
          setUserData((prevUserData) => ({
            ...prevUserData,
            page: page,
          }));
        }
        setPageCount(response.data.totalPages);
        setPageIndex(page);
      })
      .catch((error) => {
        console.error("There was an error fetching the users!", error);
        setUserData((prevUserData) => ({ ...prevUserData, loading: false }));
      });
  };

  const handlePageChange = (selected) => {
    setUserData((prevUserData) => ({
      ...prevUserData,
      page: selected.selected,
    }));
    setPageIndex(selected.selected);
    setPaginStat(true);
    fetchUsers(
      userData.selectedStatus,
      userData.search,
      selected.selected,
      paginStat
    );
  };

  const handleSearchBtn = (e, status) => {
    if (status === "searchButton") {
      setGlobalFilter(e.target.value);
      setSearchState(!searchStat);
      setSearchStatPage(true);
    } else if (e.key === "Enter") {
      console.log("e.keyvalue=============", e.key);
      setGlobalFilter(e.target.value);
      setSearchState(!searchStat);
      setSearchStatPage(true);
    } else if (status === "closeSearch") {
      setUserData((prevData) => ({
        ...prevData,
        search: "",
      }));
      setSearchState(!searchStat);
      setSearchStatPage(false);
      setPaginStat(false);
    }
    if (userData.search === "") {
      setSearchStatPage(false);
      setPaginStat(false);
    }
  };

  function formatDate(isoDateStr) {
    const date = new Date(isoDateStr);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  }

  const data = React.useMemo(() => userData.users, [userData.users]);

  const columns = React.useMemo(() => {
    const cols = [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Phone",
        accessor: "phone",
      },
      {
        Header: "Created",
        accessor: "createdAt",
        Cell: ({ value }) => <span>{formatDate(value)}</span>,
      },
      {
        Header: "Role",
        accessor: "roleId",
      },
      {
        Header: " ",
        className: "hide-on-mobile",
        Cell: ({ row }) => (
          <Link
            to={`/protected/UserProfile/${row.original.kcUserId.slice(4, 10)}`}
          >
            <button className="view-profile-button border-0 rounded-1">
              View Profile
            </button>
          </Link>
        ),
      },
    ];
    if (
      userData.selectedStatus === "guestUsers" ||
      userData.selectedStatus === "Users"
    ) {
      return cols.filter((col) => col.Header !== "role");
    } else if (tokenData.roleId === "Admin") {
      return cols.filter((col) => col.Header !== "Version");
    }
  }, [userData.selectedStatus]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    gotoPage,
  } = useTable(
    { columns, data, initialState: { pageIndex: 0 } },
    useGlobalFilter,
    usePagination
  );

  const handleRowClick = (row) => {
    navigate(`/protected/UserProfile/${row.original.kcUserId.slice(4, 10)}`);
  };

  return (
    <div className="users-panel d-flex flex-column align-items-center">
      <Helmet>
        <title> Users | Kakatiya Capital</title>
        <meta
          name="description"
          content="Admin panel for managing users on kakatiya capital. View, edit, and manage user accounts to ensure smooth operations and support."
        />
      </Helmet>

      <StatusBar
        setSelectedStatus={setUserData}
        selectedStatus={userData.selectedStatus}
      />
      {tokenData.token && tokenData.roleId === "Admin" ? (
        <Link
          to="/protected/addUsers"
          title="Add User"
          className="position-fixed addUser px-2 py-2 rounded-2 text-dark my-4"
        >
          Add Users
        </Link>
      ) : (
        <></>
      )}
      <div className="w-50 d-flex justify-content-center position-relative mt-3 mt-sm-5 ">
        <input
          value={userData.search || ""}
          placeholder="Search users..."
          className="searchBarUsers rounded-5"
          onChange={(e) => {
            setUserData((prevUserData) => ({
              ...prevUserData,
              search: e.target.value,
            }));
          }}
          onKeyPress={(e) => handleSearchBtn(e)}
        />
        {userData.search && userData.search.length > 0 && (
          <button
            className=" border-0 userSearchDelete position-absolute mt-5  rounded-circle"
            onClick={(e) => handleSearchBtn(e, "closeSearch")}
          >
            <RxCross2 />
          </button>
        )}
        <button
          className="searchIcon border-0 position-absolute end-0"
          title="search"
          onClick={(e) => handleSearchBtn(e, "searchButton")}
        >
          <FaSearch className="text-white" />
        </button>
      </div>
      <table {...getTableProps()} className="users-table rounded-2">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()} className={column.className}>
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                onClick={() => handleRowClick(row)}
                className="rowClick"
              >
                {row.cells.map((cell) => (
                  <td
                    {...cell.getCellProps()}
                    className={cell.column.className}
                  >
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      {pageCount > 1 && (
        <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          pageCount={pageCount}
          onPageChange={handlePageChange}
          containerClassName={"pagination"}
          activeClassName={"active"}
          forcePage={pageIndex} // Ensure the pagination component highlights the correct page
        />
      )}
    </div>
  );
}

function StatusBar({ setSelectedStatus, selectedStatus }) {
  return (
    <div className="status-barUsers d-flex justify-content-between align-items-center py-2 ">
      <div
        className={`status-optionUser text-center  ${
          selectedStatus === "allUsers" ? "active" : ""
        }`}
        title="All Users"
        onClick={() =>
          setSelectedStatus((prevUserData) => ({
            ...prevUserData,
            selectedStatus: "allUsers",
            page: 0,
          }))
        }
      >
        All
      </div>
      <div
        className={`status-optionUser text-center ${
          selectedStatus === "guestUsers" ? "active" : ""
        }`}
        title="Guest Users"
        onClick={() =>
          setSelectedStatus((prevUserData) => ({
            ...prevUserData,
            selectedStatus: "guestUsers",
            page: 0,
          }))
        }
      >
        Guest Users
      </div>
      <div
        className={`status-optionUser text-center ${
          selectedStatus === "Users" ? "active" : ""
        }`}
        title="Users"
        onClick={() =>
          setSelectedStatus((prevUserData) => ({
            ...prevUserData,
            selectedStatus: "Users",
            page: 0,
          }))
        }
      >
        Nbfc Representatives
      </div>
    </div>
  );
}

export default Users;
