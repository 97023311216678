import React, { useState } from "react";
import axios from "axios";
import "./letterHead.css";
import config from "../../Boot/config.json";

function LetterHead() {
  const [email, setEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const axiosInstance = axios.create({
    baseURL: `${config.baseUrl}/Users`,
  });

  function handleChange(e) {
    const { value } = e.target;
    setEmail(value);
  }

  const handleNewsLetter = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const formData = { email };
      const response = await axiosInstance.post("/NewsLetter", formData);
      console.log("response:", response.data);
      setEmail("");
      alert("Email submitted!");
    } catch (err) {
      if (err.response && err.response.data === "email already exists") {
        alert("email already exists");
      } else {
        alert("Failed to submit!");
      }
    } finally {
      setIsSubmitting(false);
    }
  };
  return (
    <div className="newsLetterWrapper">
      <div className="newsLetter d-flex flex-column flex-sm-row">
        <div className="newLetterPhrase d-flex justify-content-center ">
          <h1>Subscribe to Our Newsletter for Exclusive Updates and Offers!</h1>
        </div>
        <div className="newsLetterEmail d-flex justify-content-center align-items-center">
          <form action="" className="d-flex" onSubmit={handleNewsLetter}>
            <input
              type="email"
              placeholder="Enter Email"
              name="email"
              value={email}
              onChange={(e) => handleChange(e)}
              required
            />
            <button type="submit" disabled={isSubmitting}>
              {isSubmitting ? "Submitting..." : "Submit"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default LetterHead;
