import React from "react";
import "./home.css";

function Home() {
  return (
    <div className="homeWrapper">
      <div className="homeContentWrapper d-grid align-items-center justify-content-start">
        {/* <div>
          <img src={logo} className="companyLogo" alt="" />
        </div> */}
        <div className="financeIntro">
          <h2>Unlocking Financial Freedom: A Guide to Financial Literacy</h2>
          <div>
            Empower yourself with essential knowledge to make informed financial
            decisions and secure a prosperous future in today's fast-paced
            world.
          </div>
          <div className="mt-3">
            <a href="exploreMore ">
              <button className="exploreMoreBtn">Explore More</button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
