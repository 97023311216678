import React, { useEffect, useState, useContext } from "react";
import { Link, NavLink } from "react-router-dom";
import "./careerPage.css";
import config from "../../Boot/config.json";
import axios from "axios";
import { AuthContext } from "../../App";
import { GiPin } from "react-icons/gi";
import { RxDrawingPin } from "react-icons/rx";
import { MdDelete } from "react-icons/md";
import { Helmet } from "react-helmet";

import Card from "react-bootstrap/Card";
function CareerPage() {
  const [fav, setFav] = useState(false);
  const [JobData, setJobData] = useState([]);
  const [favCount, setfavCount] = useState(0);
  const [deleteStatus, setDeleteStatus] = useState(false);
  const { tokenData, setTokenData } = useContext(AuthContext);

  const axiosInstance = axios.create({
    baseURL: `${config.baseUrl}/Jobs`,
    headers: {
      Authorization: tokenData.token,
    },
  });
  function alertMsg(messages, alertStatus) {
    setTokenData((prevState) => ({
      ...prevState,
      alertMsg: messages,
      alertValue: true,
      alertStatus,
    }));
  }

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(`${config.baseUrl}/Jobs/getJobs`);
      setJobData(response.data.AllJobs);
      setfavCount(response.data.favCount);
    };
    fetchData();
  }, [tokenData.token, fav, deleteStatus]);

  function urlEncoded(title) {
    return title
      .toLowerCase() // Optional: convert to lowercase
      .replace(/[^a-z0-9]+/g, "-") // Replace non-alphanumeric characters with hyphens
      .replace(/^-+|-+$/g, ""); // Remove leading and trailing hyphens
  }

  const deleteJob = async (e, id) => {
    try {
      await axiosInstance.delete(`/deleteJob/${id}`);
      setDeleteStatus(!deleteStatus);
    } catch (err) {
      console.log(err);
    }
  };

  const addTWL = async (e, id, value) => {
    e.preventDefault();
    e.stopPropagation();

    try {
      const formData = {
        fav: value,
      };
      if (favCount < 20) {
        await axiosInstance.patch(`/favUnFav/${id}`, formData);
        setFav(!fav);
      } else if (favCount >= 20 && value === false) {
        await axiosInstance.patch(`/favUnFav/${id}`, formData);
        setFav(!fav);
      } else {
        alertMsg("Reached max number of pins", true);
        setTimeout(() => {
          setTokenData((prevState) => ({ ...prevState, alertValue: false }));
        }, 1500);
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div>
      <Helmet>
        <title>Careers at Kakatiya Capital</title>
        <meta
          name="description"
          content="Explore career opportunities at Kakatiya Capital. Join our team and contribute to our mission of providing innovative financial solutions and empowering individuals. Find jobs in the financial sector and contribute to the growth of the company."
        />
      </Helmet>

      <section className="homeSection bg-white position-relative">
        {tokenData.token && tokenData.roleId === "Admin" && (
          <NavLink
            to="/protected/addJob"
            className="addJob d-none d-md-block py-1 px-3 text-decoration-none position-fixed "
          >
            Add Job
          </NavLink>
        )}
        <div className="d-block d-md-flex justify-content-center align-items-center mx-auto w-75 ">
          <div className="careerContent d-flex flex-column justify-items-center text-center justify-content-center">
            <h1 className="pageHeading mb-3">
              Beyond tasks,
              <br />
              <span>we're a family uniting</span>
              <br />
              <span>for success and support</span>
            </h1>
          </div>
        </div>
      </section>
      <section className="cardsSection d-flex justify-content-center flex-column m-2 mb-4">
        <h2 className="text-center">Job Vacancies</h2>

        <div
          className="d-grid gap-2 allcards align-items-center"
          id="jobVacancySection"
        >
          {JobData.map((data, index) => (
            <div key={index} className="mx-auto ">
              <Card className="jobCard mx-3">
                <Card.Body className="jobCardsBody">
                  {tokenData.token &&
                    tokenData.roleId === "Admin" &&
                    (data.fav === true ? (
                      <GiPin
                        className="jobPin position-absolute z-1 bg-white"
                        onClick={(e) => addTWL(e, data._id, !data.fav)}
                      />
                    ) : (
                      <RxDrawingPin
                        className="jobPin position-absolute z-1 bg-white"
                        onClick={(e) => addTWL(e, data._id, true)}
                      />
                    ))}
                  <Card.Title className="fs-2 jobTitle">
                    {data.title}
                  </Card.Title>
                  <Card.Text
                    dangerouslySetInnerHTML={{
                      __html: `${data.preview}`,
                    }}
                  />
                  <Link
                    to={`/careers/${urlEncoded(
                      data.title
                    )}/${data.kcJobId.slice(4, 10)}`}
                    className="p-2 px-5 kMButton d-flex justify-content-center text-center text-decoration-none text-white bg-dark"
                  >
                    Know More
                  </Link>
                </Card.Body>
                {tokenData.token && tokenData.roleId === "Admin" && (
                  <MdDelete
                    className="deletePin"
                    onClick={(e) => deleteJob(e, data._id)}
                  />
                )}
              </Card>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
}

export default CareerPage;
