import React, { useEffect, useState, useContext, useRef } from "react";
import { Form, FormControl } from "react-bootstrap";
import JoditEditor from "jodit-react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import config from "../../Boot/config.json";
import { FaArrowLeftLong } from "react-icons/fa6";
import { AuthContext } from "../../App";
import "./jobEditor.css";

function JobEditor({
  editTitle,
  editPreview,
  editJobDescription,
  handleDraftMode,
}) {
  const { jobId } = useParams();
  const navigation = useNavigate();
  const [title, setTitle] = useState("");
  const [preview, setPreview] = useState("");
  const [jobDescription, setDescription] = useState("");
  const editor = useRef(null);

  const { tokenData, setTokenData } = useContext(AuthContext);

  const axiosInstance = axios.create({
    baseURL: `${config.baseUrl}/Jobs`,
    headers: {
      Authorization: tokenData.token,
    },
  });

  const navigate = useNavigate();
  const handleBackPage = () => {
    navigate("/Careers");
  };

  const backBtn = (jobId) => {
    return jobId ? (
      <button className="d-none">Back</button>
    ) : (
      <button
        className="backButtonj px-3 text-decoration-none position-fixed text-center "
        onClick={handleBackPage}
      >
        <FaArrowLeftLong className="me-1" />
      </button>
    );
  };

  const postBtn = (jobId) => {
    return jobId ? (
      <button
        className="bg-dark text-white mt-3 p-2 rounded-2"
        onClick={handleEditPost}
      >
        Submit
      </button>
    ) : (
      <button
        className="bg-dark text-white mt-3 p-2 rounded-2"
        onClick={handleAddPost}
      >
        Submit
      </button>
    );
  };

  useEffect(() => {
    setTitle(editTitle);
    setPreview(editPreview);
    setDescription(editJobDescription);
  }, [editTitle, editPreview, editJobDescription]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "jobTitle") {
      setTitle(value);
    }
    if (name === "cardPreview") {
      setPreview(value);
    }
  };
  const handleContentChange = (newContent) => {
    setDescription(newContent);
  };
  function alertMsg(messages, alertStatus) {
    setTokenData((prevState) => ({
      ...prevState,
      alertMsg: messages,
      alertValue: true,
      alertStatus,
    }));
  }

  const handleAddPost = (e) => {
    e.preventDefault();
    const formData = {
      title,
      preview,
      jobDescription,
    };

    try {
      axiosInstance
        .post("addJob", formData)
        .then((response) => {
          alertMsg("Job added sucessfully", true);
          setTimeout(() => {
            setTokenData((prevState) => ({ ...prevState, alertValue: false }));
          }, 1500);
          navigation("/careers");
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };
  const handleEditPost = (e) => {
    e.preventDefault();
    const formData = {
      title,
      preview,
      jobDescription,
    };
    try {
      axiosInstance
        .patch(`/editJob/${jobId}`, formData)
        .then((response) => {
          alertMsg("Sucessfully job updated", true);
          setTimeout(() => {
            setTokenData((prevState) => ({ ...prevState, alertValue: false }));
          }, 2000);
          handleDraftMode(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div className="postEditorPage d-flex justify-content-center">
      <div>{backBtn(jobId)}</div>
      <div className="jobEditorBtn position-fixed ">{postBtn(jobId)}</div>
      <div className="postEditor">
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>Job Title</Form.Label>
            <FormControl
              type="text"
              id="jobTitle"
              name="jobTitle"
              placeholder="Heading"
              value={title}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Card Preview</Form.Label>
            <FormControl
              type="text"
              id="cardPreview"
              name="cardPreview"
              placeholder='eg:"The Indian high commission in Ottawa on Friday (local time) issued "'
              onChange={handleChange}
              value={preview}
            />
          </Form.Group>

          <JoditEditor
            ref={editor}
            config={config}
            id="content"
            name="content"
            className="description"
            value={jobDescription}
            onChange={handleContentChange}
            tabIndex={1}
          />
          {/* <div className="d-flex justify-content-between">{postBtn(jobId)}</div> */}
        </Form>
      </div>
    </div>
  );
}

export default JobEditor;
