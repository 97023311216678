import React, { useState, useContext } from "react";
import financeDashboard from "../../../Assets/financeBlogDashboard.jpg";
import "./blogs.css";
import { Helmet } from "react-helmet";
import { AuthContext } from "../../../App";
import { RxCross2 } from "react-icons/rx";

function Blogs({ onSearchChange, draftsMode, setPaginationStat }) {
  const [search, setSearch] = useState("");
  const { tokenData, setTokenData } = useContext(AuthContext);

  function handleChange(e) {
    const { id, value } = e.target;
    if (id == "search") {
      setSearch(value);
      setTokenData((prevData) => ({
        ...prevData,
        blogSearch: value,
      }));
      // onSearchChange(value);
    }
  }
  const handleSearchStat = (e) => {
    e.preventDefault();
    setSearch("");
    setTokenData((prevData) => ({
      ...prevData,
      blogSearch: "",
    }));

    // Use setTimeout to call handleSearchBlog after state update
    setTimeout(() => {
      handleSearchBlog(e, true);
    }, 0);
  };

  const handleSearchBlog = (e, isClear = false) => {
    e.preventDefault();
    if (!isClear) {
      setTokenData((prevData) => ({
        ...prevData,
        blogSearch: search,
      }));
    }
    setPaginationStat(false);
    onSearchChange(isClear ? "" : search);
    console.log("search in blog comp", isClear ? "" : search);
    console.log("tds", tokenData.blogSearch);
  };

  return (
    <div className="Blogs">
      <Helmet>
        <title>
          Kakatiya Capital Blogs: Financial Awareness and NBFC Insights
        </title>
        <meta
          name="description"
          content="Explore Kakatiya Capital's blog for insights on financial awareness, NBFC partnerships, and innovative financial solutions. Stay informed and empower your financial journey."
        />
      </Helmet>

      <div className="blogsWrapper">
        <div className="d-flex justify-content-center h-100">
          <div className="blogSearch d-flex flex-column justify-content-center align-items-start">
            <div className="text-white blogsPhrase d-flex align-items-center">
              Insights and Advice: Explore the World of Finance Blogs
            </div>
            <div>
              {!draftsMode && (
                <div className="searchBar d-flex  justify-content-center">
                  <form
                    action=""
                    onSubmit={handleSearchBlog}
                    className="d-flex"
                  >
                    <div className="position-relative">
                      <input
                        type="text"
                        placeholder="Search Blogs...."
                        className="border-0"
                        id="search"
                        value={tokenData.blogSearch}
                        onChange={(e) => handleChange(e)}
                      />
                      {tokenData.blogSearch &&
                        tokenData.blogSearch.length > 0 && (
                          <RxCross2
                            className="position-absolute  crossSearch rounded-circle p-1"
                            onClick={(e) => handleSearchStat(e)}
                          />
                        )}
                    </div>
                    <button className="border-0">Search</button>
                  </form>
                </div>
              )}
            </div>
          </div>
          <div className="BlogPotImg d-flex flex-column justify-content-center align-items-center">
            <img
              src={financeDashboard}
              loading="lazy"
              alt="pot plant"
              className="blogsPotPlant rounded-circle mb-3"
            />
            {!draftsMode ? (
              <>
                <h1 className="text-white">BLOGS</h1>
              </>
            ) : (
              <>
                <h1 className="text-white">DRAFTS</h1>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Blogs;
