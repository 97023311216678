import React from "react";
import SchoolCurriculum from "../../Assets/School_Curriculum.png";
import budgeting from "../../Assets/budgeting.png";
import savingAndInvesting from "../../Assets/savingAndInvestings.png";
import moneybag from "../../Assets/money.png";
import "./financialKnowledge.css";

function FinancialKnowledge() {
  const FinancialKnowledgeData = [
    {
      icon: SchoolCurriculum,
      heading: "Personal Finance Management",
      content: `Financial literacy empowers individuals to manage their personal finances efficiently. 
      It equips them with the knowledge and skills to create budgets, track expenses, and prioritize spending, 
      leading to better financial outcomes.`,
    },
    {
      icon: budgeting,
      heading: "Budgeting",
      content: `One of the fundamental aspects of financial literacy is budgeting.
       By creating and sticking to  a budget, individuals can control their spending, avoid debt, 
        and work towards achieving their financial goals.
      `,
    },
    {
      icon: savingAndInvesting,
      heading: "Saving and Investing",
      content: `Financial literacy educates individuals about the importance of saving and investing for the future.
       It enables them to understand different investment options, 
        assess risks, and make informed decisions to grow their wealth over time.

      `,
    },
  ];
  return (
    <div>
      <div className="financialKnowledgeCards d-flex justify-content-center">
        <div className="financeCardsWrapper d-flex justify-content-center flex-column">
          <div className="Heading_moneyImg d-flex justify-content-between position-relative">
            <h1 className="financeLitHeading">
              Importance of financial literacy
            </h1>
            <img src={moneybag} className="moneyBag position-absolute" alt="" />
          </div>
          <div className="financeCards d-flex flex-wrap justify-content-center">
            {FinancialKnowledgeData.map((eachCard) => (
              <div className="financialKnwoledgeCard text-center d-flex flex-column justify-content-center align-items-center">
                <div className="financialKnowledgeImages justify-content-center align-items-center bg-white rounded-circle d-flex">
                  <img src={eachCard.icon} className="finKnowImage" alt="" />
                </div>
                <h2>{eachCard.heading}</h2>
                <div>{eachCard.content}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default FinancialKnowledge;
