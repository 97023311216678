import React from "react";
import "./impFinanceLit.css";

function ImpFinance() {
  const impFinanceCards = [
    {
      heading: "School Curriculum",
      content: `Integrating financial literacy education into school curriculums is essential for promoting financial 
        literacy from an early age. By teaching basic financial concepts in schools, young people can develop healthy financial habits and make informed financial decisions as adults.`,
    },
    {
      heading: "Online Resources",
      content: `In today's digital age, there is a wealth of online resources available to improve financial literacy. Websites, blogs, and 
        online courses offer valuable information and tools to enhance financial knowledge and skills at any stage of life.`,
    },
    {
      heading: "Workshops and Seminars",
      content: `Workshops and seminars conducted by financial experts provide individuals with practical knowledge and guidance on various financial topics.
         These interactive sessions offer opportunities to ask questions, learn from real-life examples, and gain insights into effective financial management.`,
    },
  ];
  return (
    <div className="impFinanceLiteracyWrapper">
      <div className="impFinanceLitHeading">
        <h1>Finance Literacy</h1>
      </div>
      <div className="impFinanceCards d-flex flex-wrap justify-content-center">
        {impFinanceCards.map((eachCard) => (
          <div className="financeCard">
            <h2>{eachCard.heading}</h2>
            <div>{eachCard.content}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ImpFinance;
