import React from "react";
import "./challengesInFinLit.css";
import moneyGirl from "../../Assets/moneygirl.png";

function ChallengesInFinanceLit() {
  const challengesInFinLit = [
    {
      heading: "Lack of Access to Financial Education",
      content: `Financial literacy, the ability to understand and manage personal finances effectively, is crucial for individuals to navigate the complexities of modern-day economics. \
      However, one of the significant hurdles in promoting financial literacy is the lack of access to education, especially in underserved communities.`,
    },
    {
      heading: "Complexity of Financial Products",
      content: `Financial products encompass a wide array of offerings, including loans, credit cards, insurance policies, investment instruments, and more. 
        These products serve various purposes, such as facilitating transactions, managing risk, and building wealth.`,
    },
    {
      heading: "Behavioral Biases",
      content: `Behavioral biases refer to systematic patterns of deviation from rationality in judgment,
       often influenced by psychological factors. These biases can lead individuals to make irrational decisions, despite possessing adequate information.`,
    },
    {
      heading: "School Curriculum",
      content: `  Integrating financial literacy education into school curriculums is essential for promoting 
      financial literacy from an early age. By teaching basic financial concepts in schools, 
      young people can develop healthy financial habits and make informed financial decisions as adults.`,
    },
  ];
  return (
    <div className="d-flex my-1 my-sm-5  challengesInFinLiteracy">
      <div className="d-flex flex-column align-items-center align-items-sm-center align-items-lg-end challengesInFinContent ">
        <div className="challengesInFInLitHeading">
          <h1 className="mt-5">Challenges in Financial Literacy</h1>
        </div>
        <div className="challengesInFinCardsWrapper d-flex flex-wrap my-1 my-sm-5">
          {challengesInFinLit.map((eachCard) => (
            <div className="challengesInFinCard p-3 my-1 my-sm-5">
              <h1>{eachCard.heading}</h1>
              <div>{eachCard.content}</div>
            </div>
          ))}
        </div>
      </div>
      <div className="moneyGirlImage d-flex justify-content-center align-items-center">
        <img src={moneyGirl} className="moneyGirl" alt="" />
      </div>
    </div>
  );
}

export default ChallengesInFinanceLit;
