import React from "react";
import FinancialKnowledge from "../FinancialKnowledge/FinancialKnowledge";
import Home from "../Home/Home";
import ImpFinance from "../ImpFinanceLit/ImpFinance";
import ImpactOfFinLitIndividual from "../ImpactOfFinanceLit/ImpactOfFinLitIndividual";
import ChallengesInFinanceLit from "../ChallengesInFinancialLit/ChallengesInFinanceLit";
import ImproveFinLit from "../ImproveFinancialLiteracy/ImproveFinLit";
import ContactForm from "../ContactForm/ContactForm";
import LetterHead from "../letterHead.js/LetterHead";

function KakatiyaHome() {
  return (
    <div>
      <Home />
      <ImpFinance />
      <FinancialKnowledge id="exploreMore" />
      <ImpactOfFinLitIndividual />
      <ChallengesInFinanceLit />
      <ImproveFinLit />
      <ContactForm />
      <LetterHead />
      <div className="m-0 d-flex justify-content-center bg-body-tertiary">
        <p className="m-0 mb-1">
          <b>Developed By:</b>
          <a
            href="https://svsrco.com"
            target="_blank"
            rel="noreferrer"
            className="text-decoration-none"
          >
            &nbsp;&nbsp;SVSR Communications Pvt Ltd
          </a>
        </p>
      </div>
    </div>
  );
}

export default KakatiyaHome;
