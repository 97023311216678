import React, { useState } from "react";
import axios from "axios";
import "./contactForm.css";
import instagram from "../../Assets/socialMedia_instagram.png";
import facebook from "../../Assets/socialMedia_facebook.png";
import linkedin from "../../Assets/socialMedia_linkedin.png";
import twitter from "../../Assets/socialMedia_twitter.png";
import config from "../../Boot/config.json";

function ContactForm() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
  });
  const [phoneError, setPhoneError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const axiosInstance = axios.create({
    baseURL: `${config.baseUrl}/Users`,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "phone" && !/^\d{0,10}$/.test(value)) {
      setPhoneError("Mobile number should contain only 10 digits.");
    } else {
      setPhoneError("");
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    axiosInstance
      .post("/ContactUs", formData)
      .then((response) => {
        console.log("response:", response.data);
        setFormData({
          name: "",
          email: "",
          phone: "",
        });
        alert("Form submitted!");
        setIsSubmitting(false);
      })
      .catch((error) => {
        console.error("Error submitting form:", error);
        alert("Failed to Submit!");
        setIsSubmitting(false);
      });
  };

  return (
    <div className="d-flex justify-content-center moneyWallpaper">
      <div className="d-flex justify-content-center flex-md-row flex-column contactFormWrapper">
        <div className="contactContentBlock d-flex justify-content-center justify-content-sm-end   ">
          <div className="w-75">
            <h1 className="contactFormPhrase w-75 py-5">
              Need assistance? Our team is here to help!
            </h1>
            <div className="contactDetails pb-5">
              <div>Phone: 9885548859</div>
              <div>Email: jaikisan@jaikisan.co</div>
            </div>
            <div className="pb-5 pt-2">
              <img src={instagram} className="socialMediaIcons" alt="" />
              <img src={facebook} className="socialMediaIcons" alt="" />
              <img src={linkedin} className="socialMediaIcons" alt="" />
              <img src={twitter} className="socialMediaIcons" alt="" />
            </div>
          </div>
        </div>
        <div className="contactFormDetails d-flex justify-content-center align-items-center">
          <form onSubmit={handleSubmit}>
            <div>
              <div>
                <label htmlFor="name">Name</label>
              </div>
              <input
                type="text"
                className="rounded-3 p-2 border-0"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
            </div>
            <div>
              <div>
                <label htmlFor="email">Email</label>
              </div>
              <input
                type="email"
                className="rounded-3 p-2 border-0"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div>
              <div>
                <label htmlFor="phone">Phone</label>
              </div>
              <input
                type="text"
                className="rounded-3 p-2 border-0"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                required
              />
              {phoneError && <div className="error ">{phoneError}</div>}
            </div>
            <button
              type="submit"
              className="rounded-3 p-2 border-0 my-5 contactFormBtn"
              disabled={isSubmitting}
            >
              {isSubmitting ? "Submitting..." : "Submit"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ContactForm;
