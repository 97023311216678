import React, { useEffect, useState, useContext } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import kakatiyaLogo from "../../Assets/logoKaktiyaCapital.jpg";
import { AuthContext } from "../../App";
import { NavLink, useNavigate } from "react-router-dom";
import menu from "../../Assets/menu.png";
import axios from "axios";
import config from "../../Boot/config.json";
import "./navigation.css";
import profile from "../../Assets/profileImg.jpg";

function Navigation() {
  const [shadow, setShadow] = useState("");
  const { tokenData, handleLogout } = useContext(AuthContext);
  const [userData, setUserData] = useState([]);
  const [details, setDetails] = useState({
    role: "",
    userId: "",
  });

  const navigation = useNavigate();

  const [expanded, setExpanded] = useState(false);

  const axiosInstance = axios.create({
    baseURL: `${config.baseUrl}/Users`,
    headers: {
      Authorization: tokenData.token,
    },
  });

  function navigateTo(navigateTo) {
    navigation(navigateTo);
    setExpanded(false); // Close the dropdown
  }

  // Effect to load values from session storage into state
  useEffect(() => {
    const storedToken = sessionStorage.getItem("JaikisanJWT");
    if (storedToken) {
      const authData = JSON.parse(storedToken);
      setDetails((prevData) => ({
        ...prevData,
        userId: authData.userId,
        role: authData.roleId,
      }));
    }
  }, [tokenData]);

  useEffect(() => {
    if (details.userId) {
      axiosInstance
        .get(`/User/${details.userId}`)
        .then((response) => {
          setUserData(response.data.data);
          console.log(response.data.data);
        })
        .catch((error) => {
          console.error("There was an error fetching the user data!", error);
        });
    }
  }, [details.userId, details.role, tokenData.profileStatus]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        console.log("do9es it triggering ");
        setShadow("shadow-lg");
      } else {
        setShadow("");
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Navbar expand="lg" expanded={expanded} collapseOnSelect className="bgCol">
      <Container
        fluid
        className={`position-fixed top-0 navBarWrapper py-2 ${shadow} `}
      >
        <Navbar.Brand onClick={() => navigateTo("/")}>
          <img
            src={kakatiyaLogo}
            className="d-inline-block align-top kakatiyaLogo"
            alt="Logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle
          className="custom-toggler border-0"
          onClick={() => setExpanded(!expanded)}
        >
          <img src={menu} alt="menu" loading="lazy" />
        </Navbar.Toggle>
        <Navbar.Collapse id="navbarScroll" className="justify-content-end ">
          <Nav className="my-2 my-lg-0 navElements " navbarScroll>
            <NavLink
              to="/"
              className=" text-center"
              onClick={() => navigateTo("/")}
            >
              Home
            </NavLink>
            <NavLink
              to="/careers"
              className=" text-center"
              onClick={() => navigateTo("/careers")}
            >
              Jobs
            </NavLink>
            <NavLink
              to="/blogs"
              className=" text-center"
              onClick={() => navigateTo("/blogs")}
            >
              Blogs
            </NavLink>
            {tokenData.token && (tokenData.roleId === "Admin" || "Guest") && (
              <NavLink
                to="/protected/myBlogs"
                className=" text-center"
                onClick={() => navigateTo("/protected/myBlogs")}
              >
                My Blogs
              </NavLink>
            )}
            {tokenData.token &&
              (tokenData.roleId === "User" ||
                tokenData.roleId === "Guest" ||
                tokenData.roleId === "Admin") && (
                <NavLink
                  to="/nbfcs"
                  className=" text-center"
                  onClick={() => navigateTo("/nbfcs")}
                >
                  NBFCs
                </NavLink>
              )}
            {tokenData.token && tokenData.roleId === "Admin" && (
              <NavLink
                to="/protected/Users"
                className=" text-center "
                onClick={() => navigateTo("/protected/Users")}
              >
                Users
              </NavLink>
            )}

            {tokenData.token && (
              <NavLink
                to="/Home"
                className=" text-center"
                onClick={() => {
                  handleLogout();
                  navigateTo("/Home");
                }}
              >
                Logout
              </NavLink>
            )}
          </Nav>
          <div className=" d-flex flex-column justify-content-center align-items-center">
            {tokenData.token && (
              <NavLink
                to={`/protected/UserProfile/${details.userId}`}
                title="profile"
                className="text-center profileImg rounded-circle d-flex flex-column justify-content-center align-items-center"
                onClick={() =>
                  navigateTo(`/protected/UserProfile/${userData.userId}`)
                }
              >
                <img
                  src={
                    userData.profile === ""
                      ? profile
                      : `${config.deployUrl}${userData.profile}`
                  }
                  alt={userData.name}
                  className="profileImg rounded-circle"
                  loading="lazy"
                />
              </NavLink>
            )}
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Navigation;
